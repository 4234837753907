import React, { useState, useContext, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'

import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import debounce from 'lodash.debounce'

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay)
    })
}

const SearchSelectComponent = (props) => {
    const [selectedOption, setSelectedOption] = useState([])
    const [singleOption, setSingleOption] = useState({ show: '', value: '' })
    const [optionData, setOptionData] = useState([])
    const [edit, setEdit] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [options, setOptions] = useState([])
    const [showDropdown, setShowDropdown] = useState(false)

    const [open, setOpen] = useState(false)

    const [loading, setLoading] = useState(false)

    // useEffect(() => {
    //     let active = true

    //     if (!loading) {
    //         return undefined
    //     }

    //     return () => {
    //         active = false
    //     }
    // }, [loading])

    // useEffect(() => {
    //     if (!open) {
    //         setOptions([])
    //     }
    // }, [open])

    useEffect(() => {
        if (props.options) {
            setOpen(false)
            if (props.options.length) {
                let allData = props.options
                if (props.value && edit) {
                    console.log(allData, 'allfghg')
                    setSingleOption({
                        show: allData[0].show,
                        value: props.value,
                    })
                    setEdit(false)
                    setLoading(false)
                    setOpen(false)
                } else {
                    setOptionData(allData)
                    console.log(allData, 'allfghg')
                    setLoading(false)
                    setOpen(true)
                    setShowDropdown(true)
                }
            } else {
                setOptionData([])
                setLoading(false)
                setOpen(false)
                setShowDropdown(false)
            }
        }
    }, [props.options, edit])

    const debounceFunc = debounce((value) => {
        console.log(value, 'vahjk')
        props.getAllOptions(value)
    }, 1500)

    const handleSearchChange = (value) => {
        console.log(value, 'vahjhjh')
        setLoading(true)
        setSearchValue(value)
        setSingleOption({ show: value, value: value })
        debounceFunc(value)
    }

    useEffect(() => {
        console.log(props.value, props.options.length, 'props.value')
        if (props.value && props.value !== '' && props.options && props.options.length == 0) {
            console.log(props.value, 'props.value')
            props.getAllOptions(props.value)
            setEdit(true)
        }
    }, [props.value])

    const getSingleValue = (selectedOption) => {
        console.log(selectedOption, 'selectedOption')
        setSingleOption(selectedOption)
        props.onChange(selectedOption.value)
    }

    return (
        // <Autocomplete
        //     id="asynchronous-demo"
        //     style={{ width: 300 }}
        //     open={open}
        //     onOpen={() => {
        //         setOpen(true)
        //     }}
        //     onClose={() => {
        //         setOpen(false)
        //     }}
        //     multiple={false}
        //     value={singleOption}
        //     onChange={(event, value) => {
        //         if (value) {
        //             getSingleValue(value)
        //         } else {
        //             setSingleOption({ show: '', value: '' })
        //             setOptionData([])
        //         }
        //     }}
        //     renderOption={(props, option) => (
        //         <li {...props} key={option.value}>
        //             {' '}
        //             {option.show}{' '}
        //         </li>
        //     )}
        //     getOptionLabel={(option) => option.show}
        //     options={optionData}
        //     loading={loading}
        //     renderInput={(params) => (
        //         <TextField
        //             {...params}
        //             label={props.label}
        //             variant={props.variant ? props.variant : 'outlined'}
        //             error={props.error}
        //             value={props.value}
        //             size={props.size}
        //             name={props.name}
        //             InputProps={{
        //                 ...params.InputProps,
        //                 endAdornment: (
        //                     <React.Fragment>
        //                         {loading ? <CircularProgress color="inherit" size={20} /> : null}
        //                         {params.InputProps.endAdornment}
        //                     </React.Fragment>
        //                 ),
        //             }}
        //             onKeyDown={(e) => {
        //                 if (e.key === 'Enter' && e.target.value) {
        //                     handleSearchChange(e.target.value)
        //                 } else {
        //                     setOptionData([])
        //                     setSingleOption({ show: '', value: '' })
        //                 }
        //             }}
        //             onChange={(e) => {
        //                 if (e.target.value) {
        //                     console.log(e.target.value, 'e.target.value')
        //                     handleSearchChange(e.target.value)
        //                 } else {
        //                     setOptionData([])
        //                     setSingleOption({ show: '', value: '' })
        //                 }
        //             }}
        //             helperText={props.helperText}
        //             autoFocus={props.autoFocus}
        //         />
        //     )}
        // />
        <Autocomplete
            id={props.id}
            options={optionData}
            onChange={(event, value) => {
                if (value) {
                    getSingleValue(value)
                } else {
                    setSingleOption({ show: '', value: '' })
                    setOptionData([])
                }
            }}
            value={singleOption}
            getOptionLabel={(option) => option.show}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label}
                    variant={props.variant ? props.variant : 'outlined'}
                    error={props.error}
                    value={props.value}
                    size={props.size}
                    name={props.name}
                    onChange={(e) => {
                        if (e.target.value) {
                            console.log(e.target.value, 'e.target.value')
                            handleSearchChange(e.target.value)
                        } else {
                            setOptionData([])
                            setSingleOption({ show: '', value: '' })
                        }
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    helperText={props.helperText}
                />
            )}
            autoFocus={props.autoFocus}
        />
    )
}

export default SearchSelectComponent
