//LOGO
export const LOGO = '/assets/images/ab_Primis_Medical.jpg'
export const OLDLOGO = '/assets/images/ab_Primis_Auctions.png'
export const BRAND_LOGO = '/assets/svg/logo.svg'
export const OLDBRAND_LOGO = '/assets/svg/logo.svg'
export const DPW_LOGO = '/assets/images/ab_Primis_Medical.jpg'
export const OLDDPW_LOGO = '/assets/images/ab_Primis_Auctions.jpg'
export const DPW_LOGO_WHITE = '/assets/svg/logo.svg'
export const OLDDPW_LOGO_WHITE = '/assets/svg/logo.svg'
export const VER_LOGO = '/assets/svg/logo_PrimisMedical_vertical.svg'
export const OLDVER_LOGO = '/assets/svg/logo_PrimisMedical_vertical.svg'
export const NOT_LOGO = '/assets/svg/logo_PrimisMedical_plain.svg'
export const OLDNOT_LOGO = '/assets/svg/logo_PrimisMedical_plain.svg'
export const SITE_NAME = 'The Asset Portal'
