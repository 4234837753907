import React, { useReducer } from 'react'
import InvoiceContext from './invoiceContext'
import InvoiceReducer from './invoiceReducer'
import { apiCall, apiCallSeller } from '../../common/api'
import { response } from '../common'

import {
    GET_ALL_INVOICE,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_ALL_USER_INVOICES,
    GET_ALL_USER_TRANSACTIONS,
    GET_ALL_INVOICES,
    GET_SINGLE_INVOICE,
    GET_INVOICE_DETAILS,
} from './invoiceTypes'

const InvoiceState = (props) => {
    const initialState = {
        search_allinvoiceproducts: {
            invoicerecords: [],
            transactionrecords: [],
            appointmentrecord: {},
            locationrecord: {},
            cartvalues: {},
            invoiceuserrecords: [],
            invoiceaddressrecord: [],
            invoicesellerrecord: [],
        },
        search_alluserinvoices: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            from: '',
        },
        search_allusertransactions: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            from: '',
        },
        all_invoices: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        single_invoice: {
            invoicerecords: [],
            transactionrecords: [],
            appointmentrecord: {},
            locationrecord: {},
            cartvalues: {},
            from: '',
        },
        invoice_details: {
            invoicerecords: [],
            transactionrecords: [],
            appointmentrecord: {},
            locationrecord: {},
            cartvalues: {},
            orderControlLogs: [],
            from: '',
        },
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(InvoiceReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getAllInvoiceProducts = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'invoice', formData, '', 'invoice')])
            const from = 'invoicesearch'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_INVOICE,
                    payload: {
                        invoicerecords: res.data.data.responseData.invoiceItems.length
                            ? res.data.data.responseData.invoiceItems
                            : [],
                        transactionrecords: res.data.data.responseData.allTransactions.length
                            ? res.data.data.responseData.allTransactions
                            : [],
                        appointmentrecord: res.data.data.responseData.appointmentDetail,
                        locationrecord: res.data.data.responseData.locationDetail,
                        cartvalues: res.data.data.responseData.cartValues,
                        invoiceuserrecords: res.data.data.responseData.invoiceUser.length
                            ? res.data.data.responseData.invoiceUser
                            : [],
                        invoiceaddressrecord: res.data.data.responseData.invoiceAddress
                            ? res.data.data.responseData.invoiceAddress
                            : [],
                        invoicesellerrecord: res.data.data.responseData.invoiceSeller
                            ? res.data.data.responseData.invoiceSeller
                            : [],
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllReturnInvoice = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'returninvoice', formData, '', 'invoice'),
            ])
            const from = 'invoicesearch'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_INVOICE,
                    payload: {
                        invoicerecords: res.data.data.responseData.invoiceItems.length
                            ? res.data.data.responseData.invoiceItems
                            : [],
                        transactionrecords: res.data.data.responseData.allTransactions.length
                            ? res.data.data.responseData.allTransactions
                            : [],
                        appointmentrecord: res.data.data.responseData.appointmentDetail
                            ? res.data.data.responseData.appointmentDetail
                            : [],
                        locationrecord: res.data.data.responseData.locationDetail
                            ? res.data.data.responseData.locationDetail
                            : [],
                        cartvalues: res.data.data.responseData.cartValues,
                        invoiceuserrecord: res.data.data.responseData.invoiceUser
                            ? res.data.data.responseData.invoiceUser
                            : [],
                        invoiceaddressrecord: res.data.data.responseData.invoiceAddress
                            ? res.data.data.responseData.invoiceAddress
                            : [],
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllUserInvoices = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'allinvoices', formData, '', 'invoice'),
            ])
            const from = 'allinvoices'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_USER_INVOICES,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        from: fromVariable,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('cartsearch')
        }
    }

    const getAllSellerUserInvoices = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'invoices', formData, '', 'seller/invoice'),
            ])
            const from = 'allinvoices'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_USER_INVOICES,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        from: fromVariable,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('cartsearch')
        }
    }

    const getAllUserTransactions = async (formData, fromVariable) => {
        const from = 'alltransactions'
        try {
            const [res] = await Promise.all([apiCall('post', from, formData, '', 'invoice')])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_USER_TRANSACTIONS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        from: fromVariable,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getAllInvoices = async (formData, from) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'invoices', formData, '', 'seller/invoice'),
            ])
            const fromVariable = from ? from : 'Invoices'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_INVOICES,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        from: fromVariable,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const changeProductStatus = async (formData, fromVariable) => {
        try {
            const from = fromVariable ? fromVariable : 'productchange'
            const [res] = await Promise.all([
                apiCall('post', 'changeStatus', formData, '', 'seller/invoice'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getSingleInvoice = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'invoice', formData, '', 'seller/user/invoice'),
            ])
            const from = fromVariable ? fromVariable : 'InvoiceManagement'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_INVOICE,
                    payload: {
                        invoicerecords: res.data.data.responseData.invoiceItems.length
                            ? res.data.data.responseData.invoiceItems
                            : [],
                        transactionrecords: res.data.data.responseData.allTransactions.length
                            ? res.data.data.responseData.allTransactions
                            : [],
                        appointmentrecord: res.data.data.responseData.appointmentDetail
                            ? res.data.data.responseData.appointmentDetail
                            : [],
                        locationrecord: res.data.data.responseData.locationDetail
                            ? res.data.data.responseData.locationDetail
                            : [],
                        cartvalues: res.data.data.responseData.cartValues,
                        invoiceuserrecord: res.data.data.responseData.invoiceUser
                            ? res.data.data.responseData.invoiceUser
                            : [],
                        invoiceaddressrecord: res.data.data.responseData.invoiceAddress
                            ? res.data.data.responseData.invoiceAddress
                            : [],
                        from: from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const updateInvoice = async (formData) => {
        try {
            const from = 'invoiceupdate'
            const [res] = await Promise.all([
                apiCall('post', 'update_invoice', formData, '', 'seller/user/invoice'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getInvoiceDetails = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCallSeller('post', 'getInvoiceDetails', formData, '', 'invoice'),
            ])
            const from = fromVariable ? fromVariable : 'InvoiceDetails'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_INVOICE_DETAILS,
                    payload: {
                        invoicerecords: res.data.data.responseData.invoiceItems.length
                            ? res.data.data.responseData.invoiceItems
                            : [],
                        transactionrecords: res.data.data.responseData.allTransactions.length
                            ? res.data.data.responseData.allTransactions
                            : [],
                        appointmentrecord: res.data.data.responseData.appointmentDetail
                            ? res.data.data.responseData.appointmentDetail
                            : [],
                        locationrecord: res.data.data.responseData.locationDetail
                            ? res.data.data.responseData.locationDetail
                            : [],
                        cartvalues: res.data.data.responseData.cartValues,
                        invoiceuserrecord: res.data.data.responseData.invoiceUser
                            ? res.data.data.responseData.invoiceUser
                            : [],
                        invoiceaddressrecord: res.data.data.responseData.invoiceAddress
                            ? res.data.data.responseData.invoiceAddress
                            : [],
                        from: from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <InvoiceContext.Provider
            value={{
                all_invoices: state.all_invoices,
                search_allinvoiceproducts: state.search_allinvoiceproducts,
                search_alluserinvoices: state.search_alluserinvoices,
                search_allusertransactions: state.search_allusertransactions,
                single_invoice: state.single_invoice,
                invoice_details: state.invoice_details,
                responseStatus: state.responseStatus,
                clearResponse,
                getAllInvoiceProducts,
                getAllReturnInvoice,
                getAllUserInvoices,
                updateInvoice,
                getAllUserTransactions,
                getAllInvoices,
                changeProductStatus,
                getSingleInvoice,
                getAllSellerUserInvoices,
                getInvoiceDetails,
            }}
        >
            {props.children}
        </InvoiceContext.Provider>
    )
}

export default InvoiceState
