import React, { useEffect, useContext, Suspense } from 'react'
import { Route, BrowserRouter as Router, withRouter, Switch } from 'react-router-dom'
import Loaders from '../custom/components/molecules/Loaders'

import commonContext from '../product/context/common/commonContext'
import AuthContext from '../product/context/auth/authContext'
import CustomCommonContext from '../custom/context/common/commonContext'
import ProductCommonContext from '../product/context/common/commonContext'

import Header from '../custom/components/molecules/Header'
import Footer from '../custom/components/molecules/Footer'
import PrivateRoute from './privateRoute'
const SwitchUser = React.lazy(() => import('../views/SwitchUser'))
const Login = React.lazy(() => import('../views/Login'))

const ForgotPassword = React.lazy(() => import('../views/ForgotPassword'))
const ResetPassword = React.lazy(() => import('../views/ResetPassword'))

const Search = React.lazy(() => import('../views/Search'))
const MyAuctions = React.lazy(() => import('../views/Dashboard/MyAuctions'))
const Registration = React.lazy(() => import('../views/Registration'))

const IndividualRegistration = React.lazy(() =>
    import('../views/Registration/IndividualRegistration'),
)
const CompanyRegistration = React.lazy(() => import('../views/Registration/CompanyRegistration'))
const Profile = React.lazy(() => import('../views/Dashboard/Profile/index'))
const ChangePassword = React.lazy(() => import('../views/Dashboard/Profile/ChangePassword'))
const Documents = React.lazy(() => import('../views/Dashboard/Profile/Documents'))
const tapMatchers = React.lazy(() => import('../views/Dashboard/tapMachers'))
const Offers = React.lazy(() => import('../views/Dashboard/Offers'))
const MyInvoices = React.lazy(() => import('../views/Dashboard/MyInvoices'))
const MyCardDetails = React.lazy(() => import('../views/Dashboard/MyCardDetails'))
const MyBankDetails = React.lazy(() => import('../views/Dashboard/MyBankDetails'))
const MyDeposits = React.lazy(() => import('../views/Dashboard/MyDeposits'))
const MyTransactions = React.lazy(() => import('../views/Dashboard/MyTransactions'))
const Invoice = React.lazy(() => import('../views/Invoice'))
const DepositInvoice = React.lazy(() => import('../views/DepositInvoice'))

const ProductView = React.lazy(() => import('../views/ProductView'))
const LiveAuction = React.lazy(() => import('../views/ProductView/LiveAuction'))
const ProductViewInvite = React.lazy(() => import('../views/ProductView/ProductView_Invite'))
const Home = React.lazy(() => import('../views/Home'))
const Cart = React.lazy(() => import('../views/Cart'))
const BuynowCart = React.lazy(() => import('../views/BuyNowCart'))
const MakeofferCart = React.lazy(() => import('../views/MakeOfferCart'))
const Checkout = React.lazy(() => import('../views/Checkout'))

const MyWallet = React.lazy(() => import('../views/Dashboard/MyWallet'))
const ErrorPage = React.lazy(() => import('../views/ErrorPage'))
const Auction = React.lazy(() => import('../views/Auction'))
const VerifyEmail = React.lazy(() => import('../views/VerifyEmail'))

const Notifications = React.lazy(() => import('../views/Dashboard/Notifications'))
const ContactUs = React.lazy(() => import('../views/ContactUs'))
const AssetPortal = React.lazy(() => import('../views/AssetPortal'))
const StaticPage = React.lazy(() => import('../views/Static'))
const SavedSearch = React.lazy(() => import('../views/Dashboard/SavedSearch'))
const RosoomResponse = React.lazy(() => import('../views/Rosoom/Response'))
const ResendVerifyMail = React.lazy(() => import('../views/ResendVerifyMail'))
const SellerDashboard = React.lazy(() => import('../views/Seller/SellerDashboard'))
const SellerProfile = React.lazy(() => import('../views/Seller/Profile/index'))
const SellerChangePassword = React.lazy(() => import('../views/Seller/Profile/ChangePassword'))
const SellerDocuments = React.lazy(() => import('../views/Seller/Profile/Documents'))
const PostProject = React.lazy(() => import('../views/Seller/PostProject'))
const SellerMyAuctions = React.lazy(() => import('../views/Seller/MyAuctions'))
const SellerSavedCard = React.lazy(() => import('../views/Seller/SavedCard'))
const SellerSavedSearch = React.lazy(() => import('../views/Seller/SavedSearch'))
const SellerReports = React.lazy(() => import('../views/Seller/Reports'))

const SellerListing = React.lazy(() => import('../views/Seller/Listing'))
const SellerAuctionLots = React.lazy(() => import('../views/Seller/AuctionLots'))
const SellerInvoice = React.lazy(() => import('../views/Seller/Invoice/index'))
const Message = React.lazy(() => import('../product/views/Message'))

const Routes = () => {
    const authContext = useContext(AuthContext)
    const { loadUser, callEnd } = authContext
    const customCommonContext = useContext(CustomCommonContext)
    const productCommonContext = useContext(ProductCommonContext)

    const { getGlobalVariable } = customCommonContext
    const { getAllCountries, getAllStates } = productCommonContext
    const { loaderSet } = useContext(commonContext)

    useEffect(() => {
        loaderSet(true)
        if (localStorage.token) {
            loadUser(localStorage.token)
        } else {
            loaderSet(false)
        }

        getGlobalVariable()
        getAllCountries()
        getAllStates()
    }, [])

    useEffect(() => {
        if (callEnd) loaderSet(false)
    }, [callEnd])

    return (
        <>
            <Header />
            <Suspense fallback={<Loaders name="home" isLoading={true} />}>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/forgot_password" component={ForgotPassword} />
                    <Route exact path="/reset_password/:token" component={ResetPassword} />
                    <Route exact path="/registration" component={Registration} />
                    <Route exact path="/resend_verify_mail" component={ResendVerifyMail} />
                    <Route
                        exact
                        path="/registration_individual"
                        component={IndividualRegistration}
                    />
                    <Route exact path="/registration_company" component={CompanyRegistration} />
                    <Route exact path="/search" component={Search} />
                    <Route exact path="/timedauction/:pid" component={ProductView} />
                    <Route exact path="/allAuction/:aid" component={LiveAuction} />
                    <Route exact path="/auction/:action" component={Auction} />
                    <Route exact path="/invite" component={ProductViewInvite} />
                    <Route exact path="/contact" component={ContactUs} />
                    <Route exact path="/asset_portal" component={AssetPortal} />
                    <Route exact path="/saved_search" component={SavedSearch} />
                    <Route exact path="/verify_email/:token" component={VerifyEmail} />
                    <Route exact path="/buynowcart" component={BuynowCart} />
                    <Route exact path="/myoffers" component={MakeofferCart} />
                    <Route exact path="/checkout/:type/:cart_id" component={Checkout} />
                    <Route exact path="/cart" component={Cart} />
                    <Route exact path="/auctions/:type" component={MyAuctions} />
                    <Route exact path="/watchlist/:type" component={MyAuctions} />
                    <Route exact path="/purchases/:type" component={MyAuctions} />
                    <Route exact path="/account/profile" component={Profile} />
                    <Route exact path="/account/change" component={ChangePassword} />
                    <Route exact path="/account/notifications" component={Notifications} />
                    <Route exact path="/account/documents" component={Documents} />
                    <Route exact path="/tapmatches" component={tapMatchers} />
                    <Route exact path="/alloffers" component={Offers} />
                    {/* Payment routes */}
                    <Route exact path="/invoices/:action" component={MyInvoices} />
                    <Route exact path="/payments/card_details" component={MyCardDetails} />
                    <Route exact path="/payments/bank_details" component={MyBankDetails} />
                    <Route exact path="/payments/transactions" component={MyTransactions} />
                    <Route exact path="/transactions" component={MyTransactions} />
                    <Route exact path="/invoice/:id" component={Invoice} />
                    <Route exact path="/depositinvoice/:id" component={DepositInvoice} />
                    <Route exact path="/deposits/:action" component={MyDeposits} />
                    <Route exact path="/wallet" component={MyWallet} />
                    <Route exact path="/rosoom_success" component={RosoomResponse} />
                    {/* Seller routes */}
                    {/* <Route exact path="/seller/dashboard" component={SellerDashboard} />
                    <Route exact path="/seller/post-project" component={PostProject} />
                    <Route exact path="/seller/profile" component={SellerProfile} />
                    <Route exact path="/seller/change" component={SellerChangePassword} />
                    <Route exact path="/seller/notifications" component={Notifications} />
                    <Route exact path="/seller/documents" component={SellerDocuments} />
                    <Route exact path="/seller/auctions/:type" component={SellerMyAuctions} />
                    <Route exact path="/seller/saved-cards" component={SellerSavedCard} />
                    <Route exact path="/seller/saved_search" component={SellerSavedSearch} />
                    <Route exact path="/seller/listing/:action" component={SellerListing} />
                    <Route exact path="/seller/auction/:action" component={SellerAuctionLots} />
                    <Route exact path="/seller/invoices/:action" component={MyInvoices} />
                    <Route exact path="/seller/sellerinvoices/:action" component={SellerInvoice} />
                    <Route exact path="/seller/reports" component={SellerReports} /> */}
                    <Route exact path="/switch" component={SwitchUser} />
                    <Route exact path="/messages" component={Message} />

                    <Route
                        exact
                        path={[
                            '/about',
                            '/services',
                            '/help',
                            '/guidelines',
                            '/privacy_policy',
                            '/terms_of_use',
                            '/faq',
                            '/how_it_works',
                            '/blog',
                            '/refund_policy',
                            '/terms_of_service',
                            '/shipping_policy',
                            '/why_tap',
                            '/our_team',
                            '/asset_disposition',
                            '/certified_appraisals',
                            '/white_glove',
                            '/charitable_donations',
                            '/testimonials',
                            '/facility_closures_and_relocations',
                        ]}
                        component={StaticPage}
                    />
                    <Route exact path="*" component={ErrorPage} />
                </Switch>
                <Footer />
            </Suspense>
            {/* <Suspense fallback={<Loaders name="home" />}> */}
            {/* </Suspense> */}
        </>
    )
}
export default withRouter(Routes)
