import React, { useState, useContext, useEffect } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { LOGO, VER_LOGO, NOT_LOGO, DPW_LOGO } from '../../../../Utils'
import { Link } from 'react-router-dom'
import {
    Badge,
    Button,
    Divider,
    ListItem,
    Menu,
    MenuItem,
    SwipeableDrawer,
} from '@material-ui/core'
import AuthContext from '../../../../product/context/auth/authContext'
import CommonContext from '../../../../product/context/common/commonContext'
import CartContext from '../../../../product/context/cart/cartContext'
import AlertContext from '../../../../product/context/alert/alertContext'
import { handleRedirectInternal, useCustomMediaQuery } from '../../../../product/common/components'
import './Header.css'
import AutopltContext from '../../../../product/context/autopilot/autopltContext'
import UserContext from '../../../../product/context/user/userContext'
import ProductContext from '../../../../product/context/product/productContext'
import BidHistory from '../../../components/organisms/BidHistory'
import TopHeader from './TopHeader'
import LanguageSwitch from '../LanguageSwitch'
import BuynowContext from '../../../../product/context/buynow/buynowContext'
import PayContext from '../../../../product/context/payment/payContext'
import CreditContext from '../../../../product/context/credit/creditContext'
import OfflineContext from '../../../../product/context/offline/offlineContext'
import Loaders from '../Loaders'
import { Popover } from '@material-ui/core'
const Header = () => {
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const commonContext = useContext(CommonContext)
    const productContext = useContext(ProductContext)
    const payContext = useContext(PayContext)
    const cartContext = useContext(CartContext)
    const creditContext = useContext(CreditContext)
    const offlineContext = useContext(OfflineContext)
    const userContext = useContext(UserContext)
    const autopltContext = useContext(AutopltContext)
    const buynowContext = useContext(BuynowContext)
    //const makeofferContext = useContext(makeofferContext)
    const { setAlert } = alertContext
    const {
        user,
        isAuthenticated,
        loadPendingCount,
        isAdmin,
        cartCount,
        logout,
        responseStatus: responseStatusAuth,
        clearResponse: clearResponseAuth,
    } = authContext

    const [state, setState] = useState({
        right: false,
        top: false,
    })
    const history = useHistory()
    const [anchormenu, setanchormenu] = useState(null)
    const [anchorcart, setanchorcart] = useState(null)

    const {
        responseStatus: responseStatusProduct,
        clearResponse: clearResponseProduct,
    } = productContext
    const {
        responseStatus: responseStatusPayment,
        clearResponse: clearResponsePayment,
    } = payContext

    const { responseStatus: responseStatusCart, clearResponse: clearResponseCart } = cartContext

    const { responseStatus: responseStatusUser, clearResponse: clearResponseUser } = userContext
    const {
        responseStatus: responseStatusCredit,
        clearResponse: clearResponseCredit,
    } = creditContext

    const {
        responseStatus: responseStatusOffline,
        clearResponse: clearResponseOffline,
    } = offlineContext

    const {
        responseStatus: responseStatusAutoPlt,
        clearResponse: clearResponseAutoPlt,
    } = autopltContext

    const {
        responseStatus: responseStatusBuyNow,
        clearResponse: clearResponseBuyNow,
        getAllCartProducts,
        search_allproducts,
    } = buynowContext

    // const {
    //     responseStatus: responseStatusMakeOffer,
    //     clearResponse: clearResponseMakeOffer,
    // } = makeofferContext

    const { searchValue, setSearchValue, isLoading } = commonContext
    const [searchText, setSearchText] = useState('')
    let [cartProducts, setCartProducts] = useState([])
    const [search, setSearch] = useState({})

    useEffect(() => {
        if (isAuthenticated) {
            getAllCartProducts(search)
        }
    }, [isAuthenticated])

    useEffect(() => {
        setCartProducts(search_allproducts.cartrecords.length ? search_allproducts.cartrecords : [])
    }, [search_allproducts])

    useEffect(() => {
        setCartProducts([])
        return () => {
            setCartProducts([])
        }
    }, [])

    useEffect(() => {
        if (responseStatusUser) {
            if (!responseStatusUser.noAlert) {
                setAlert(responseStatusUser.message, responseStatusUser.status)
            }
            clearResponseUser()
        }
        if (responseStatusAutoPlt) {
            setAlert(responseStatusAutoPlt.message, responseStatusAutoPlt.status)
            clearResponseAutoPlt()
        }
        if (responseStatusAuth) {
            if (responseStatusAuth.from === 'register') {
                if (responseStatusAuth.status === 'success') {
                    setAlert(
                        'Successfully registered! Please check your email to verify and complete account creation',
                        responseStatusAuth.status,
                    )
                    clearResponseAuth()
                } else {
                    setAlert(responseStatusAuth.message, responseStatusAuth.status)
                    clearResponseAuth()
                }
            } else {
                if (!responseStatusAuth.noAlert) {
                    setAlert(responseStatusAuth.message, responseStatusAuth.status)
                }
                clearResponseAuth()
            }
        }
        if (responseStatusBuyNow) {
            setAlert(responseStatusBuyNow.message, responseStatusBuyNow.status)
            clearResponseBuyNow()
        }
        // if (responseStatusMakeOffer) {
        //     setAlert(responseStatusMakeOffer.message, responseStatusMakeOffer.status)
        //     clearResponseMakeOffer()
        // }
        if (responseStatusProduct) {
            if (!responseStatusProduct.noAlert) {
                setAlert(responseStatusProduct.message, responseStatusProduct.status)
            }
            clearResponseProduct()
        }
        if (responseStatusPayment) {
            setAlert(responseStatusPayment.message, responseStatusPayment.status)
            clearResponsePayment()
        }
        if (responseStatusCart) {
            setAlert(responseStatusCart.message, responseStatusCart.status)
            clearResponseCart()
        }
        if (responseStatusCredit) {
            setAlert(responseStatusCredit.message, responseStatusCredit.status)
            clearResponseCredit()
        }
        if (responseStatusOffline) {
            setAlert(responseStatusOffline.message, responseStatusOffline.status)
            clearResponseOffline()
        }
    }, [
        responseStatusUser,
        responseStatusProduct,
        responseStatusAutoPlt,
        responseStatusAuth,
        responseStatusBuyNow,
        //responseStatusMakeOffer,
        responseStatusPayment,
        responseStatusCart,
        responseStatusCredit,
        responseStatusOffline,
    ])

    useEffect(() => {
        if (responseStatusPayment) {
            if (responseStatusPayment.from === 'payment') {
                if (responseStatusPayment.status === 'success') {
                    if (responseStatusPayment.data && responseStatusPayment.data.url) {
                        const newWindow = window.open(
                            responseStatusPayment.data.url,
                            '_self',
                            'noopener,noreferrer',
                        )
                        if (newWindow) newWindow.opener = null
                    }
                }
            }
        }
    }, [responseStatusPayment])

    // useEffect(() => {
    //     if (searchValue) {
    //         handleRedirectInternal(history, `search`)
    //     }
    // }, [searchValue])

    const onLogOut = () => {
        logout()
        setAlert('Logged out successfully', 'success')
        handleRedirectInternal(history, '')
        setanchormenu(null)
        setanchorcart(null)
        setSearchText('')
    }

    const toggleDrawer = (anchor, open) => {
        setState({ ...state, [anchor]: open })
    }

    const handleCart = (event) => {
        setanchorcart(event.currentTarget)
    }
    const handleMenu = (event) => {
        setanchormenu(event.currentTarget)
    }

    const handleClose = (history, path) => {
        setanchormenu(null)
        setanchorcart(null)
        if (history && path && path !== 'backdropClick') {
            console.log('history', history, path)
            handleRedirectInternal(history, path)
        }
    }

    const getSearchBarObjet = (value) => {
        return {
            limit: 12,
            page: 1,
            orderby: 'p.id, desc',
            order: '',
            filters: {
                searchbar: {
                    value: value,
                    type: 'like',
                    field: 'p.title,p.desc_proc',
                },
            },
        }
    }

    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value)
    }

    const handleSearchButtonClick = () => {
        if (searchText != '') {
            setSearchValue(getSearchBarObjet(searchText))
            setState({ ...state, top: false })
            if (location.pathname != '/search') {
                handleRedirectInternal(history, 'search')
            }
        } else {
            if (location.pathname != '/search') {
                handleRedirectInternal(history, 'search')
            }
            // setSearch({
            //     ...search,
            //     filters: {
            //         ...search.filters,
            //         searchbar: {
            //             ...search.filters.searchbar,
            //             value: searchValue.filters.searchbar.value,
            //         },
            //     },
            // })
        }
    }

    const handleSearchKeyDown = (e) => {
        if (e.key === 'Enter') {
            setSearchValue(getSearchBarObjet(searchText))
            if (location.pathname != '/search') {
                handleRedirectInternal(history, 'search')
            }
        }
    }

    useEffect(() => {
        if (searchValue && searchValue.filters && searchValue.filters.searchbar) {
            setSearchText(searchValue.filters.searchbar.value)
        }
    }, [searchValue])

    useEffect(() => {
        if (isAuthenticated) {
            let auctionFilter = {
                table: 'buynow',
                field: 'id',
                extraField: '',
                filters: {
                    buynow_autype: {
                        value: 'live',
                        type: 'in',
                        field: 'b.buynow_autype',
                    },
                    paid: {
                        value: '0',
                        type: 'in',
                        field: 'b.paid',
                    },
                    partial: {
                        value: '0',
                        type: 'in',
                        field: 'b.partial',
                    },
                },
            }
            let buyNowFilter = {
                table: 'cart_temp',
                field: 'cart_id',
                extraField: '',
                filters: {
                    paid: {
                        value: '0',
                        type: 'in',
                        field: 'crt.paid',
                    },
                },
            }
            let makeofferFilter = {
                table: 'makeoffer_temp',
                field: 'cart_id',
                extraField: '',
                filters: {
                    status: {
                        value: '1',
                        type: 'in',
                        field: 'mot.status',
                    },
                    paid: {
                        value: '0',
                        type: 'in',
                        field: 'mot.paid',
                    },
                },
            }

            loadPendingCount(buyNowFilter, 'buynowCount')

            loadPendingCount(auctionFilter, 'auctionCount')

            loadPendingCount(makeofferFilter, 'makeofferCount')
        }
    }, [isAuthenticated, history.location.pathname])

    const [count, setCount] = useState({
        auctionCount: 0,
        buynowCount: 0,
        makeofferCount: 0,
    })

    useEffect(() => {
        if (cartCount?.auctionCount) {
            setCount({ ...count, auctionCount: cartCount.auctionCount.totalRecords })
        }
        if (cartCount?.buynowCount) {
            setCount({ ...count, buynowCount: cartCount.buynowCount.totalRecords })
        }
        if (cartCount?.makeofferCount) {
            setCount({ ...count, makeofferCount: cartCount.makeofferCount.totalRecords })
        }
    }, [cartCount])

    const [anchorElAboutUs, setAnchorElAboutUs] = useState(null)
    const [anchorElAuction, setanchorElAuction] = useState(null)
    const [anchorElService, setanchorElService] = useState(null)
    const [anchorElAccount, setanchorElAccount] = useState(null)
    const openauc = Boolean(anchorElAuction)
    const opensvc = Boolean(anchorElService)
    const open = Boolean(anchorElAboutUs)
    const openAccount = Boolean(anchorElAccount)
    const idauc = openauc ? 'simple-popover' : undefined
    const idsvc = opensvc ? 'simple-popover' : undefined
    const id = open ? 'simple-popover' : undefined
    const idAccount = openAccount ? 'simple-popover' : undefined

    const handleAboutUsClick = (event) => {
        setAnchorElAboutUs(event.currentTarget)
    }

    const handleAboutUsClose = () => {
        setAnchorElAboutUs(null)
    }

    const handleAuctionClick = (event) => {
        setanchorElAuction(event.currentTarget)
    }

    const handleAuctionClose = () => {
        setanchorElAuction(null)
    }

    const handleAccountClick = (event) => {
        setanchorElAccount(event.currentTarget)
    }

    const handleAccountClose = () => {
        setanchorElAccount(null)
    }

    const handleServiceClick = (event) => {
        setanchorElService(event.currentTarget)
    }

    const handleServiceClose = () => {
        setanchorElService(null)
    }

    const handlePopFunction = () => {
        toggleDrawer('right', false)
        window.scrollTo(0, 0)
        handleAboutUsClose()
        handleAuctionClose()
        handleServiceClose()
        handleAccountClose()
    }
    return (
        <>
            {useCustomMediaQuery('(min-width: 1024px)') ? (
                <>
                    {/* DESKTOP NAVIGATION */}
                    <Loaders name="home" isLoading={isLoading} />

                    <header className="mainHeader noPrint">
                        <div className="customContainer d-flex justify-content-between align-items-center h-100">
                            <div className="headLt d-flex justify-content-start align-items-center">
                                <Link id="projectname_logo" to="/" className="hdLogo">
                                    <img src={DPW_LOGO} alt="The Asset Portal" />
                                </Link>
                            </div>
                            <div className="menuRtOtr">
                                <div className="d-flex align-items-center justify-content-between mb-4">
                                    <div className="form-inline my-2 my-lg-0 headerSearchForm">
                                        <input
                                            className="form-control"
                                            type="search"
                                            placeholder="Search"
                                            aria-label="Search"
                                            value={searchText}
                                            onChange={handleSearchTextChange}
                                            onKeyDown={handleSearchKeyDown}
                                        />
                                        <button className="btn" onClick={handleSearchButtonClick}>
                                            <span id="header_search" className="material-icons">
                                                search
                                            </span>
                                        </button>
                                    </div>
                                    <TopHeader />
                                </div>
                                <div className="headRt deskNav d-flex justify-content-between align-items-center">
                                    <ul className="d-flex justify-content-between align-items-center mx-auto">
                                        <li>
                                            <NavLink activeClassName="active" to="/" exact>
                                                {/* <span className="material-icons-outlined">
                                                    home
                                                </span> */}
                                                Home
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                id="header_product"
                                                activeClassName="active"
                                                to="/search"
                                                exact
                                            >
                                                {/* <span className="material-icons-outlined">
                                                    home
                                                </span> */}
                                                Products
                                            </NavLink>
                                        </li>
                                        <li>
                                            <Button
                                                id="header_auction"
                                                aria-describedby={idauc}
                                                activeClassName="active"
                                                onClick={handleAuctionClick}
                                            >
                                                Auctions
                                                <span className="material-icons">
                                                    keyboard_arrow_down
                                                </span>
                                            </Button>

                                            <Popover
                                                id={idauc}
                                                open={openauc}
                                                anchorEl={anchorElAuction}
                                                onClick={handleAuctionClose}
                                                onClose={handleAuctionClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <ul
                                                    className="aboutUsMenu"
                                                    onClick={handleAuctionClose}
                                                >
                                                    {/* <li>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/auction/live"
                                                        >
                                                            Live Auctions{' '}
                                                        </NavLink>
                                                    </li> */}
                                                    <li>
                                                        <NavLink
                                                            id="header_timed_auction"
                                                            activeClassName="active"
                                                            to="/auction/timed"
                                                        >
                                                            Timed Auctions
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink
                                                            id="header_upcomming_auction"
                                                            activeClassName="active"
                                                            to="/auction/upcoming"
                                                        >
                                                            Upcoming Auctions
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink
                                                            id="header_upcomming_auction"
                                                            activeClassName="active"
                                                            to="/auction/featured"
                                                        >
                                                            Featured Auctions
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink activeClassName="active" to="/faq">
                                                            Auction FAQ{' '}
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </Popover>
                                        </li>
                                        <li>
                                            <Button
                                                id="header_service"
                                                aria-describedby={idsvc}
                                                activeClassName="active"
                                                onClick={handleServiceClick}
                                            >
                                                Service
                                                <span className="material-icons">
                                                    keyboard_arrow_down
                                                </span>
                                            </Button>

                                            <Popover
                                                id={idsvc}
                                                open={opensvc}
                                                anchorEl={anchorElService}
                                                onClose={handleServiceClose}
                                                onClick={handleServiceClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <ul
                                                    className="aboutUsMenu"
                                                    onClick={handleServiceClose}
                                                >
                                                    <li>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/asset_disposition"
                                                        >
                                                            Asset Disposition
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/services"
                                                        >
                                                            Auction Services
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/charitable_donations"
                                                        >
                                                            Charitable Donations
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/certified_appraisals"
                                                        >
                                                            Certified Appraisals
                                                        </NavLink>
                                                    </li>

                                                    <li>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/facility_closures_and_relocations"
                                                        >
                                                            Facility Closures and Relocations
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/our_team"
                                                        >
                                                            Our Team
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/asset_portal"
                                                        >
                                                            The Asset Portal
                                                        </NavLink>
                                                    </li>

                                                    <li>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/white_glove"
                                                        >
                                                            White Glove Services
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </Popover>
                                        </li>
                                        {isAuthenticated ? (
                                            <li>
                                                <Button
                                                    id="header_mybid"
                                                    aria-describedby={id}
                                                    activeClassName="active"
                                                    onClick={handleAboutUsClick}
                                                >
                                                    My Bids
                                                    <span className="material-icons">
                                                        keyboard_arrow_down
                                                    </span>
                                                </Button>

                                                <Popover
                                                    id={id}
                                                    open={open}
                                                    anchorEl={anchorElAboutUs}
                                                    onClose={handleAboutUsClose}
                                                    onClick={handleAboutUsClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <ul
                                                        className="aboutUsMenu"
                                                        onClick={handleAboutUsClose}
                                                    >
                                                        <li>
                                                            <NavLink
                                                                id="mybid_all"
                                                                activeClassName="active"
                                                                to="/auctions/all"
                                                            >
                                                                All
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink
                                                                id="mybid_winning"
                                                                activeClassName="active"
                                                                to="/auctions/winning"
                                                            >
                                                                Winning
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink
                                                                id="mybid_outbid"
                                                                activeClassName="active"
                                                                to="/auctions/outbid"
                                                            >
                                                                Currently Outbid
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink
                                                                id="mybid_won"
                                                                activeClassName="active"
                                                                to="/auctions/won"
                                                            >
                                                                Won
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink
                                                                id="mybid_lost"
                                                                activeClassName="active"
                                                                to="/auctions/lost"
                                                            >
                                                                Lost
                                                            </NavLink>
                                                        </li>
                                                    </ul>
                                                </Popover>
                                            </li>
                                        ) : null}

                                        <li>
                                            <NavLink
                                                id="header_contact"
                                                activeClassName="active"
                                                to="/contact"
                                                exact
                                            >
                                                Contact Us
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </header>
                </>
            ) : (
                <>
                    {/* MOBILE / TAB NAVIGATION */}
                    <header className="mainHeader customContainer d-flex justify-content-between align-items-center">
                        <div className="headLt d-flex justify-content-start">
                            <Link to="/" className="hdLogo">
                                <img src={DPW_LOGO} alt="The Asset Portal Logo" />
                            </Link>
                        </div>
                        <div className="headRt">
                            <ul className="d-flex justify-content-start ml-auto align-items-center">
                                <li>
                                    <TopHeader />
                                </li>
                                <li>
                                    <Button onClick={() => toggleDrawer('top', true)}>
                                        <span className="material-icons">search</span>
                                    </Button>
                                </li>
                                <li>
                                    <Button
                                        className="respNavBtn"
                                        onClick={() => toggleDrawer('right', true)}
                                    >
                                        <span className="material-icons">menu</span>
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </header>
                    <React.Fragment>
                        <SwipeableDrawer
                            className="respHeaderSearch"
                            anchor={'top'}
                            open={state['top']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={() => toggleDrawer('top', false)}
                            onOpen={() => toggleDrawer('top', true)}
                        >
                            <div className="form-inline my-2 my-lg-0 headerSearchForm">
                                <input
                                    className="form-control"
                                    type="search"
                                    placeholder={'search'}
                                    aria-label="Search"
                                    value={searchText}
                                    onChange={handleSearchTextChange}
                                    onKeyDown={handleSearchKeyDown}
                                />
                                <Button className="btn" onClick={handleSearchButtonClick}>
                                    <span className="material-icons">search</span>
                                </Button>
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>

                    <React.Fragment>
                        <SwipeableDrawer
                            className="headerDrawer"
                            anchor={'right'}
                            open={state['right']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={() => toggleDrawer('right', false)}
                            onOpen={() => toggleDrawer('right', true)}
                        >
                            <div className="headRt respNav d-flex justify-content-start align-items-center">
                                <div className="naLogoHead d-flex justify-content-between align-items-center">
                                    <Link to="/">
                                        <img
                                            src={DPW_LOGO}
                                            alt="The Asset Portal Logo"
                                            height="60"
                                        />
                                    </Link>
                                    <Button
                                        className="headDrawerClose"
                                        onClick={() => toggleDrawer('right', false)}
                                    >
                                        <span className="material-icons">clear</span>
                                    </Button>
                                </div>
                                <Divider />
                                <ul className="mobile-menu">
                                    <li onClick={() => toggleDrawer('right', false)}>
                                        <NavLink activeClassName="active" to="/" exact>
                                            Home
                                        </NavLink>
                                    </li>
                                    <li onClick={() => toggleDrawer('right', false)}>
                                        <NavLink activeClassName="active" to="/search" exact>
                                            Products
                                        </NavLink>
                                    </li>
                                    <li>
                                        <Button
                                            aria-describedby={id}
                                            activeClassName="active"
                                            onClick={handleAuctionClick}
                                        >
                                            Auctions
                                            <span className="material-icons">
                                                keyboard_arrow_down
                                            </span>
                                        </Button>

                                        <Popover
                                            id={idauc}
                                            open={openauc}
                                            anchorEl={anchorElAuction}
                                            onClose={handleAuctionClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                        >
                                            <ul className="aboutUsMenu" onClick={handlePopFunction}>
                                                {/* <li onClick={() => toggleDrawer('right', false)}>
                                                    <NavLink activeClassName="active" to="/auction">
                                                        Live Auctions{' '}
                                                    </NavLink>
                                                </li> */}
                                                <li onClick={() => toggleDrawer('right', false)}>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/auction/timed"
                                                    >
                                                        Timed Auctions
                                                    </NavLink>
                                                </li>
                                                <li onClick={() => toggleDrawer('right', false)}>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/auction/upcoming"
                                                    >
                                                        Upcoming Auctions
                                                    </NavLink>
                                                </li>
                                                <li onClick={() => toggleDrawer('right', false)}>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/auction/featured"
                                                    >
                                                        Featured Auctions
                                                    </NavLink>
                                                </li>
                                                <li onClick={() => toggleDrawer('right', false)}>
                                                    <NavLink activeClassName="active" to="/faq">
                                                        Auction FAQ{' '}
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </Popover>
                                    </li>
                                    <li>
                                        <Button
                                            aria-describedby={idsvc}
                                            activeClassName="active"
                                            onClick={handleServiceClick}
                                        >
                                            Service
                                            <span className="material-icons">
                                                keyboard_arrow_down
                                            </span>
                                        </Button>

                                        <Popover
                                            id={idsvc}
                                            open={opensvc}
                                            anchorEl={anchorElService}
                                            onClose={handleServiceClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                        >
                                            <ul className="aboutUsMenu" onClick={handlePopFunction}>
                                                <li onClick={() => toggleDrawer('right', false)}>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/asset_disposition"
                                                    >
                                                        Asset Disposition
                                                    </NavLink>
                                                </li>
                                                <li onClick={() => toggleDrawer('right', false)}>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/services"
                                                    >
                                                        Auction Services
                                                    </NavLink>
                                                </li>
                                                <li onClick={() => toggleDrawer('right', false)}>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/charitable_donations"
                                                    >
                                                        Charitable Donations
                                                    </NavLink>
                                                </li>
                                                <li onClick={() => toggleDrawer('right', false)}>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/certified_appraisals"
                                                    >
                                                        Certified Appraisals
                                                    </NavLink>
                                                </li>

                                                <li onClick={() => toggleDrawer('right', false)}>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/facility_closures_and_relocations"
                                                    >
                                                        Facility Closures and Relocations
                                                    </NavLink>
                                                </li>
                                                <li onClick={() => toggleDrawer('right', false)}>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/our_team"
                                                    >
                                                        Our Team
                                                    </NavLink>
                                                </li>
                                                <li onClick={() => toggleDrawer('right', false)}>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/asset_portal"
                                                    >
                                                        The Asset Portal
                                                    </NavLink>
                                                </li>

                                                <li onClick={() => toggleDrawer('right', false)}>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/white_glove"
                                                    >
                                                        White Glove Services
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </Popover>
                                    </li>
                                    {/* <li>
                                        <Button
                                            aria-describedby={id}
                                            activeClassName="active"
                                            onClick={handleAboutUsClick}
                                        >
                                            About Us
                                            <span className="material-icons">
                                                keyboard_arrow_down
                                            </span>
                                        </Button>

                                        <Popover
                                            id={id}
                                            open={open}
                                            anchorEl={anchorElAboutUs}
                                            onClose={handleAboutUsClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                        >
                                            <ul className="aboutUsMenu" onClick={handlePopFunction}>
                                                <li onClick={() => toggleDrawer('right', false)}>
                                                    <NavLink activeClassName="active" to="/">
                                                        What we do
                                                    </NavLink>
                                                </li>
                                                <li onClick={() => toggleDrawer('right', false)}>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/our_team"
                                                    >
                                                        Our Team
                                                    </NavLink>
                                                </li>
                                                <li onClick={() => toggleDrawer('right', false)}>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/services"
                                                    >
                                                        Who We Services
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink activeClassName="active" to="/">
                                                        Charities We support
                                                    </NavLink>
                                                </li>
                                                <li onClick={() => toggleDrawer('right', false)}>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/services"
                                                    >
                                                        Partnerships
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </Popover>
                                    </li> */}

                                    {isAuthenticated && (
                                        <>
                                            <li>
                                                <Button
                                                    id="header_mybid"
                                                    aria-describedby={id}
                                                    activeClassName="active"
                                                    onClick={handleAboutUsClick}
                                                >
                                                    My Bids
                                                    <span className="material-icons">
                                                        keyboard_arrow_down
                                                    </span>
                                                </Button>

                                                <Popover
                                                    id={id}
                                                    open={open}
                                                    anchorEl={anchorElAboutUs}
                                                    onClose={handleAboutUsClose}
                                                    onClick={handleAboutUsClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <ul
                                                        className="aboutUsMenu"
                                                        onClick={handlePopFunction}
                                                    >
                                                        <li>
                                                            <NavLink
                                                                id="mybid_all"
                                                                activeClassName="active"
                                                                to="/auctions/all"
                                                            >
                                                                All
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink
                                                                id="mybid_winning"
                                                                activeClassName="active"
                                                                to="/auctions/winning"
                                                            >
                                                                Winning
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink
                                                                id="mybid_outbid"
                                                                activeClassName="active"
                                                                to="/auctions/outbid"
                                                            >
                                                                Currently Outbid
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink
                                                                id="mybid_won"
                                                                activeClassName="active"
                                                                to="/auctions/won"
                                                            >
                                                                Won
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink
                                                                id="mybid_lost"
                                                                activeClassName="active"
                                                                to="/auctions/lost"
                                                            >
                                                                Lost
                                                            </NavLink>
                                                        </li>
                                                    </ul>
                                                </Popover>
                                            </li>

                                            <li>
                                                <Button
                                                    id="header_myacc"
                                                    aria-describedby={idAccount}
                                                    activeClassName="active"
                                                    onClick={handleAccountClick}
                                                >
                                                    My Account
                                                    <span className="material-icons">
                                                        keyboard_arrow_down
                                                    </span>
                                                </Button>

                                                <Popover
                                                    id={idAccount}
                                                    open={openAccount}
                                                    anchorEl={anchorElAccount}
                                                    onClose={handleAccountClose}
                                                    onClick={handleAccountClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <ul
                                                        className="aboutUsMenu"
                                                        onClick={handlePopFunction}
                                                    >
                                                        <li>
                                                            <NavLink
                                                                id="myacc_profile"
                                                                activeClassName="active"
                                                                to="/account/profile"
                                                            >
                                                                Profile
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink
                                                                id="myacc_change"
                                                                activeClassName="active"
                                                                to="/account/change"
                                                            >
                                                                Change password
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink
                                                                id="myacc_documents"
                                                                activeClassName="active"
                                                                to="/account/documents"
                                                            >
                                                                Documents
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink
                                                                id="myacc_notifications"
                                                                activeClassName="active"
                                                                to="/account/notifications"
                                                            >
                                                                My Notifications
                                                            </NavLink>
                                                        </li>
                                                    </ul>
                                                </Popover>
                                            </li>

                                            <li onClick={() => toggleDrawer('right', false)}>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/purchases/buynow"
                                                    exact
                                                >
                                                    Priority Items
                                                </NavLink>
                                            </li>
                                            <li onClick={() => toggleDrawer('right', false)}>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/alloffers"
                                                    exact
                                                >
                                                    All Offers
                                                </NavLink>
                                            </li>
                                            {/* <li onClick={() => toggleDrawer('right', false)}>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/tapmatches"
                                                    exact
                                                >
                                                    Tap Matches
                                                </NavLink>
                                            </li> */}
                                            {/* <li onClick={() => toggleDrawer('right', false)}>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/myoffers"
                                                    exact
                                                >
                                                    Offers
                                                </NavLink>
                                            </li> */}
                                            <li onClick={() => toggleDrawer('right', false)}>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/watchlist/watchlist"
                                                    exact
                                                >
                                                    Watchlist
                                                </NavLink>
                                            </li>
                                            <li onClick={() => toggleDrawer('right', false)}>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/invoices/allview"
                                                    exact
                                                >
                                                    My Invoice
                                                </NavLink>
                                            </li>
                                            <li onClick={() => toggleDrawer('right', false)}>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/payments/transactions"
                                                    exact
                                                >
                                                    Payments
                                                </NavLink>
                                            </li>
                                            <li onClick={() => toggleDrawer('right', false)}>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/saved_search"
                                                    exact
                                                >
                                                    My Saved Searches
                                                </NavLink>
                                            </li>
                                        </>
                                    )}
                                    <li onClick={() => toggleDrawer('right', false)}>
                                        <NavLink activeClassName="active" to="/contact" exact>
                                            Contact Us
                                        </NavLink>
                                    </li>
                                    {/* <li onClick={toggleDrawer('right', false)}>
                                        <NavLink activeClassName="active" to="/blog" exact>
                                            Blog
                                        </NavLink>
                                    </li> */}
                                </ul>
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>
                </>
            )}
            <BidHistory />
        </>
    )
}
export default Header
