import React, { useReducer } from 'react'
import NethuntContext from './nethuntContext'
import NethuntReducer from './nethuntReducer'
import { apiCall } from '../../../product/common/api'
import { response } from '../../../product/context/common'

import { RESPONSE_STATUS, CLEAR_RESPONSE } from './nethuntTypes'

const NetHuntState = (props) => {
    const initialState = {
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(NethuntReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const createRecord = async (payload) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'create_record', payload, '', 'custom/index/nethunt/api'),
            ])
            resp.commonResponse(res.data, 'createRecord')
        } catch (err) {
            resp.commonErrorResponse('createRecord')
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <NethuntContext.Provider
            value={{
                responseStatus: state.responseStatus,
                createRecord,
                clearResponse,
            }}
        >
            {props.children}
        </NethuntContext.Provider>
    )
}

export default NetHuntState
