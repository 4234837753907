import {
    GET_ALL_INVOICE,
    GET_ALL_USER_INVOICES,
    GET_ALL_USER_TRANSACTIONS,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_ALL_INVOICES,
    GET_SINGLE_INVOICE,
    GET_INVOICE_DETAILS,
} from './invoiceTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_ALL_INVOICES:
            return {
                ...state,
                all_invoices: action.payload,
            }
        case GET_SINGLE_INVOICE:
            return {
                ...state,
                single_invoice: action.payload,
            }
        case GET_ALL_USER_INVOICES:
            return {
                ...state,
                search_alluserinvoices: action.payload,
            }
        case GET_ALL_USER_TRANSACTIONS:
            return {
                ...state,
                search_allusertransactions: action.payload,
            }

        case GET_ALL_INVOICE:
            return {
                ...state,
                search_allinvoiceproducts: action.payload,
            }
        case GET_INVOICE_DETAILS:
            return {
                ...state,
                invoice_details: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
