import React, { useState, useEffect, useContext } from 'react'
import { Link, NavLink, useHistory } from 'react-router-dom'
import './Header.css'
import { Button, Popover, MenuItem, MenuList, ListItem, Badge } from '@material-ui/core'
import AuthContext from '../../../../product/context/auth/authContext'
import AlertContext from '../../../../product/context/alert/alertContext'
import { handleRedirectInternal } from '../../../../product/common/components'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const TopHeader = () => {
    const [menuAnchor, setMenuAnchor] = useState(null)
    const [anchorcart, setanchorcart] = useState(null)
    const [anchormenu, setanchormenu] = useState(null)
    const [notiDropDown, setNotiDropDown] = useState(null)

    const menuOpen = Boolean(menuAnchor)
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)

    const { user, isAuthenticated, logout, cartCount } = authContext
    const { setAlert } = alertContext

    const handleMenuOpen = (event) => {
        setMenuAnchor(event.currentTarget)
    }

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        }
    }
    const handleCart = (event) => {
        setanchorcart(event.currentTarget)
    }
    const handleMenu = (event) => {
        setanchormenu(event.currentTarget)
    }
    const handleMenuNoti = (event) => {
        setNotiDropDown(event.currentTarget)
    }
    const handleClose = (history, path) => {
        setanchormenu(null)
        setNotiDropDown(null)
        setanchorcart(null)
        if (history && path && path !== 'backdropClick') {
            handleRedirectInternal(history, path)
        }
    }
    const handleMenuClose = (history, path) => {
        setMenuAnchor(null)
        if (history && path && path !== 'backdropClick') {
            handleRedirectInternal(history, path)
        }
    }

    const onLogOut = () => {
        logout()
        setAlert('Logged out successfully', 'success')
        handleRedirectInternal(history, '')
        setMenuAnchor(null)
    }
    const [count, setCount] = useState({
        auctionCount: 0,
        buynowCount: 0,
        makeofferCount: 0,
    })

    useEffect(() => {
        if (cartCount?.auctionCount) {
            setCount({ ...count, auctionCount: cartCount.auctionCount.totalRecords })
        }
        if (cartCount?.buynowCount) {
            setCount({ ...count, buynowCount: cartCount.buynowCount.totalRecords })
        }
        if (cartCount?.makeofferCount) {
            setCount({ ...count, makeofferCount: cartCount.makeofferCount.totalRecords })
        }
    }, [cartCount])

    const mobile = useMediaQuery('(max-width:767px)')
    return (
        <div className="topHeader d-flex justify-content-end align-items-center noPrint">
            {isAuthenticated ? (
                <>
                    <ul className="customContainer d-flex justify-content-end align-items-center">
                        <li>
                            <Button
                                id="header_user_detail"
                                aria-describedby={'simple-popover'}
                                onClick={handleMenuOpen}
                            >
                                <img src="/assets/svg/Icon-user.svg" alt="The Asset Portal" />{' '}
                                {!mobile && 'Hi,'}{' '}
                                {!mobile && user?.first_name + ' ' + user?.last_name}
                            </Button>
                            <Popover
                                id={'simple-popover'}
                                open={menuOpen}
                                anchorEl={menuAnchor}
                                onClose={handleMenuClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <ul className="headerDropMenu">
                                    <ListItem
                                        id="header_mybids"
                                        button
                                        onClick={() => handleMenuClose(history, 'auctions/all')}
                                    >
                                        <span className="material-icons">gavel</span>
                                        My bids
                                    </ListItem>
                                    <ListItem
                                        id="header_profile_two"
                                        button
                                        onClick={() => handleMenuClose(history, 'account/profile')}
                                    >
                                        <span className="material-icons">person</span>
                                        Profile
                                    </ListItem>
                                    <ListItem
                                        id="header_profile_two"
                                        button
                                        onClick={() => handleMenuClose(history, 'messages')}
                                    >
                                        <span className="material-icons">message</span>
                                        Messages
                                    </ListItem>
                                    {/*user?.admin ? (
                                        <>
                                            <ListItem
                                                button
                                                onClick={() =>
                                                    handleMenuClose(history, 'seller/dashboard')
                                                }
                                            >
                                                <span className="material-icons">dashboard</span>
                                                Seller Dashboard
                                            </ListItem>
                                            <ListItem
                                                button
                                                onClick={() =>
                                                    handleMenuClose(history, 'seller/post-project')
                                                }
                                            >
                                                <span className="material-icons">inventory_2</span>
                                                Post Product
                                            </ListItem>
                                        </>
                                    ) : (
                                        ''
                                    )*/}
                                    <ListItem
                                        id="header_logout_two"
                                        button
                                        onClick={() => onLogOut()}
                                    >
                                        <span className="material-icons">power_settings_new</span>
                                        Logout
                                    </ListItem>
                                </ul>
                            </Popover>
                        </li>
                        <li>
                            <Button
                                id="shopping_cart_icon"
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={handleCart}
                            >
                                <img src="/assets/svg/Icon-cart.svg" alt="The Asset Portal" />
                                {count.buynowCount + count.auctionCount + count.makeofferCount}
                                &nbsp;
                                {!mobile && 'items'}
                            </Button>

                            <Popover
                                anchorEl={anchorcart}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                id="simple-cart"
                                keepMounted
                                class={`headerDropMenu ${document.body.dir === 'rtl' && 'rtl'}`}
                                open={Boolean(anchorcart)}
                                onClose={handleClose}
                            >
                                <MenuList
                                    autoFocusItem={open}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                >
                                    <MenuItem
                                        id="header_cart"
                                        onClick={() => handleClose(history, 'buynowcart')}
                                    >
                                        <Badge badgeContent={count.buynowCount} color="primary">
                                            <span
                                                id="shopping_cart_icon"
                                                className="material-icons"
                                            >
                                                shopping_bag
                                            </span>
                                        </Badge>
                                        {'Cart'}
                                    </MenuItem>
                                    <MenuItem
                                        id="header_offerings"
                                        onClick={() => handleClose(history, 'myoffers')}
                                    >
                                        <Badge badgeContent={count.makeofferCount} color="primary">
                                            <span className="material-icons">shopping_bag</span>
                                        </Badge>
                                        {'My Offerings'}
                                    </MenuItem>
                                    <MenuItem
                                        id="header_auction_invoice"
                                        onClick={() => handleClose(history, 'cart')}
                                    >
                                        <Badge badgeContent={count.auctionCount} color="primary">
                                            <span className="material-icons">gavel</span>
                                        </Badge>

                                        {'Auction Invoice'}
                                    </MenuItem>
                                </MenuList>
                            </Popover>
                        </li>
                    </ul>
                </>
            ) : (
                <ul className="d-flex justify-content-end align-items-center">
                    <li className="authLinks d-flex align-items-center">
                        <NavLink id="header_login" to="/login" activeclassname="active">
                            Login
                        </NavLink>
                        &nbsp; / &nbsp;
                        <NavLink id="header_register" to="/registration" activeclassname="active">
                            Register
                        </NavLink>
                    </li>
                </ul>
            )}
        </div>
    )
}
export default TopHeader
