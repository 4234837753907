import React, { useReducer } from 'react'
import ProductContext from './productContext'
import ProductReducer from './productReducer'
import { apiCall, apiCallSeller } from '../../common/api'
import { response } from '../common'

import {
    GET_ALL_INVOICE,
    GET_ALL_SEARCH,
    GET_ALL_SIMILAR,
    GET_ALL_BID_HISTORY,
    GET_ALL_TTWBID_HISTORY,
    GET_ALL_ACTIVE_SORTS,
    GET_ALL_DASHBOARD,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_ALL_USER_INVOICES,
    GET_ALL_USER_RETURNS,
    GET_ALL_RETURN_INVOICE,
    GET_SAVED_SEARCHES,
    TTWBID_HISTORY_BY_USER,
    GET_ALL_PRODUCTS,
    GET_FOLLOWERS,
    GET_TOTAL_AUCTIONS_WON,
    GET_ALL_UPCOMING_PRODUCT,
    GET_TOTAL_AUCTIONS_PLACED,
    TOTAL_PRIVATE_SALE_PRODUCTS,
    GET_ALL_OFFERS,
    SAVED_SEARCH_MODAL,
    PHONE_MODEL_POPUP,
} from './productTypes'

const ProductState = (props) => {
    const initialState = {
        search_alldashboardproducts: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            sorts: {},
            userinfo: {},
        },
        active_sorts: {
            sorts: null,
        },
        search_allsimilar: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        search_allproducts: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            sorts: {},
        },
        search_allbidhistory: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            sorts: {},
        },
        search_all_upcomingproducts: {
            records: [],
        },
        search_allttwbidhistory: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            sorts: {},
        },
        search_allinvoiceproducts: {
            invoicerecords: [],
            transactionrecords: [],
            appointmentrecord: {},
            locationrecord: {},
            cartvalues: {},
        },
        search_allreturninvoiceproducts: {
            invoicerecords: [],
            transactionrecords: [],
            appointmentrecord: {},
            locationrecord: {},
            cartvalues: {},
        },
        search_alluserinvoices: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        search_alluserreturns: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        saved_searches: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        followers: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        ttwBidsByUser: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        listingFromAdmin: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        all_offers: {
            records: [],
            totalRecords: 0,
            from: '',
        },
        totalAuctionsWon: null,
        totalAuctionsPlaced: null,
        responseStatus: null,
        totalPrivateSaleProducts: 0,
        savedSearchModel: false,
        phonemodelPopup: false,
    }

    const [state, dispatch] = useReducer(ProductReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getAllDashboardProducts = async (formData, from) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'dashboard', formData, '', 'product')])
            let formvalue = from || 'search'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_DASHBOARD,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        sorts: res.data.data.responseData.sorts,
                        userinfo: res.data.data.responseData.userinfo || {},
                        from: formvalue,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const changeOfferStatus = async (formData, from) => {
        try {
            //const from = 'productchange'
            from = from ? from : 'offerChange'
            const [res] = await Promise.all([
                apiCall('post', 'changeOfferStatus', formData, '', 'seller/offer'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllAdminOffers = async (formData, from) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'adminoffers', formData, '', 'seller/offer'),
            ])
            from = from ? from : 'getAllAdminOffers'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_OFFERS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        from: from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getTtwAllDashboardProducts = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'ttw_dashboard', formData, '', 'product'),
            ])
            const from = 'search'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_DASHBOARD,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        sorts: res.data.data.responseData.sorts,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllInvoiceProducts = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'invoice', formData, '', 'product')])
            const from = 'invoicesearch'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_INVOICE,
                    payload: {
                        invoicerecords: res.data.data.responseData.invoiceItems.length
                            ? res.data.data.responseData.invoiceItems
                            : [],
                        transactionrecords: res.data.data.responseData.allTransactions.length
                            ? res.data.data.responseData.allTransactions
                            : [],
                        appointmentrecord: res.data.data.responseData.appointmentDetail,
                        locationrecord: res.data.data.responseData.locationDetail,
                        cartvalues: res.data.data.responseData.cartValues,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllReturnInvoiceProducts = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'returninvoice', formData, '', 'product'),
            ])
            const from = 'invoicesearch'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_RETURN_INVOICE,
                    payload: {
                        invoicerecords: res.data.data.responseData.invoiceItems.length
                            ? res.data.data.responseData.invoiceItems
                            : [],
                        transactionrecords: res.data.data.responseData.allTransactions.length
                            ? res.data.data.responseData.allTransactions
                            : [],
                        appointmentrecord: res.data.data.responseData.appointmentDetail,
                        locationrecord: res.data.data.responseData.locationDetail,
                        cartvalues: res.data.data.responseData.cartValues,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllUserInvoices = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'allinvoices', formData, '', 'product'),
            ])
            const from = 'allinvoices'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_USER_INVOICES,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('cartsearch')
        }
    }

    const getAllUserReturns = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'allreturns', formData, '', 'product'),
            ])
            const from = 'allreturns'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_USER_RETURNS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('cartsearch')
        }
    }

    const getAllSearchProducts = async (formData, fromVariable, custom_url = '') => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'search', formData, '', `${custom_url}product`),
            ])
            const from = fromVariable ? fromVariable : 'search'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_SEARCH,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        sorts: res.data.data.responseData.sorts,
                        from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllUpcomingProducts = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'upcomingProduct', formData, '', 'product'),
            ])
            console.log(res.data.data.responseData, 'ressdd')
            const from = fromVariable ? fromVariable : 'upcomingProduct'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_UPCOMING_PRODUCT,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllSearchProductsManifest = async (formData, fromVariable) => {
        try {
            console.log('Calling..')
            const [res] = await Promise.all([
                apiCall('post', 'searchManifest', formData, '', 'product'),
            ])
            const from = fromVariable ? fromVariable : 'search'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_SEARCH,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllActiveSorts = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'activeSorts', formData, '', 'product'),
            ])
            const from = 'search'
            if (res.data.status === 'success') {
                const respData = res.data.data.responseData.sorts
                dispatch({
                    type: GET_ALL_ACTIVE_SORTS,
                    payload: {
                        sorts: respData,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllSimilarProducts = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'search', formData, '', 'product')])
            const from = 'similar'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_SIMILAR,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const increaseProductViewCount = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'product_view_count', formData, '', 'product'),
            ])
            const from = 'similar'
            if (res.data.status === 'success') {
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const addWatchlist = async (formData, fromVariable, message) => {
        try {
            const from = fromVariable ? fromVariable : 'addWatchlist'
            const [res] = await Promise.all([apiCall('post', 'addWatchlist', formData, '', 'user')])
            if (res?.data?.status === 'success') {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: {
                        status: res?.data.status,
                        message: message ? message : res?.data?.data?.message,
                        type: res?.data?.data?.responseType,
                        data: res?.data?.data?.responseData,
                        from: from,
                    },
                })
            } else {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const removeWatchlist = async (formData, fromVariable, message) => {
        try {
            const from = fromVariable ? fromVariable : 'removeWatchlist'
            const [res] = await Promise.all([
                apiCall('post', 'removeWatchlist', formData, '', 'user'),
            ])
            if (res?.data?.status === 'success') {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: {
                        status: res?.data.status,
                        message: message ? message : res?.data?.data?.message,
                        type: res?.data?.data?.responseType,
                        data: res?.data?.data?.responseData,
                        from: from,
                    },
                })
            } else {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const addSavedSearch = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'addSavedSearch', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'saved_search_msg')
        } catch (err) {
            resp.commonErrorResponse('saved_search_msg')
        }
    }

    const setSavedSearchModel = () => {
        dispatch({
            type: SAVED_SEARCH_MODAL,
        })
    }

    const setPhonemodelPopup = () => {
        dispatch({
            type: PHONE_MODEL_POPUP,
        })
    }

    const getSavedSearch = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getSavedSearch', formData, '', 'user'),
            ])
            const from = fromVariable ? fromVariable : 'getSavedSearch'
            dispatch({
                type: GET_SAVED_SEARCHES,
                payload: {
                    records: res.data.data.responseData.records.length
                        ? res.data.data.responseData.records
                        : [],
                    totalRecords: res.data.data.responseData.totalRecords,
                    setDisp: res.data.data.responseData.setDisp,
                    from: from,
                },
            })
        } catch (err) {
            resp.commonErrorResponse('saved_search')
        }
    }

    const removeSavedSearch = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'removeSavedSearch', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'saved_search_msg')
        } catch (err) {
            resp.commonErrorResponse('saved_search_msg')
        }
    }

    const getFollowers = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'getFollowers', formData, '', 'user')])
            dispatch({
                type: GET_FOLLOWERS,
                payload: {
                    records: res.data.data.responseData.records.length
                        ? res.data.data.responseData.records
                        : [],
                    totalRecords: res.data.data.responseData.totalRecords,
                    setDisp: res.data.data.responseData.setDisp,
                },
            })
        } catch (err) {
            resp.commonErrorResponse('follower')
        }
    }

    const addFollower = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'addFollower', formData, '', 'user')])
            resp.commonResponse(res.data, 'add_follower_msg')
        } catch (err) {
            resp.commonErrorResponse('add_follower_msg')
        }
    }

    const removeFollower = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'removeFollower', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'remove_follower_msg')
        } catch (err) {
            resp.commonErrorResponse('remove_follower_msg')
        }
    }

    const updateFollower = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateFollower', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'update_follower_msg')
        } catch (err) {
            resp.commonErrorResponse('update_follower_msg')
        }
    }

    const getAllBidHistory = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'bidhistory', formData, '', 'product'),
            ])
            const from = 'search'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_BID_HISTORY,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        sorts: res.data.data.responseData.sorts,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllTtwBidHistory = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'ttwbidhistory', formData, '', 'product'),
            ])
            const from = 'search'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_TTWBID_HISTORY,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        sorts: res.data.data.responseData.sorts,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllTtwBidHistoryByUser = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'ttwbidhistory', formData, '', 'product'),
            ])
            const from = 'ttwBidHistoryByUser'
            if (res.data.status === 'success') {
                dispatch({
                    type: TTWBID_HISTORY_BY_USER,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        sorts: res.data.data.responseData.sorts,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getTrackChart = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getTrackChartMetric', formData, '', 'chartmetric'),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse(res.data, 'track_chart', true)
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('track_chart')
        }
    }

    const getAllProductsFromAdmin = async (formData, from) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'listings', formData, '', 'admin/product'),
            ])
            from = from ? from : 'listingFromAdmin'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_PRODUCTS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        from: from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getTotalAuctionsWon = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'totalAuctionswon', formData, '', 'product'),
            ])
            const from = 'search'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_TOTAL_AUCTIONS_WON,
                    payload: {
                        records: res.data.data.responseData.records
                            ? res.data.data.responseData.records
                            : 0,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getTotalAuctionsPlaced = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'totalAuctionsPlaced', formData, '', 'product'),
            ])
            const from = 'search'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_TOTAL_AUCTIONS_PLACED,
                    payload: {
                        records: res.data.data.responseData.records
                            ? res.data.data.responseData.records
                            : 0,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getTotalPrivateSaleProducts = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'totalPrivateSaleProducts', formData, '', 'product'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: TOTAL_PRIVATE_SALE_PRODUCTS,
                    payload: {
                        records: res.data.data.responseData ? res.data.data.responseData : 0,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const updateChartmetricArtistImage = async (formData, noAlert) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateChartmetricArtistImage', formData, '', 'product'),
            ])
            const from = 'updateChartmetricArtistImage'
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const listingPartipationRequest = async (formData, noAlert) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'projectAccessRequest', formData, '', 'product'),
            ])
            const from = 'projectAccessRequest'
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const uploadXLSXFile = async (formData) => {
        const from = 'uploadXLSXFile'
        try {
            const [res] = await Promise.all([apiCallSeller('post', from, formData, '', 'product')])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <ProductContext.Provider
            value={{
                // buyer_allproducts: state.buyer_allproducts,
                search_allproducts: state.search_allproducts,
                search_alldashboardproducts: state.search_alldashboardproducts,
                search_allreturninvoiceproducts: state.search_allreturninvoiceproducts,
                search_allinvoiceproducts: state.search_allinvoiceproducts,
                search_alluserinvoices: state.search_alluserinvoices,
                search_alluserreturns: state.search_alluserreturns,
                active_sorts: state.active_sorts,
                search_allbidhistory: state.search_allbidhistory,
                search_allttwbidhistory: state.search_allttwbidhistory,
                search_allsimilar: state.search_allsimilar,
                responseStatus: state.responseStatus,
                saved_searches: state.saved_searches,
                followers: state.followers,
                ttwBidsByUser: state.ttwBidsByUser,
                listingFromAdmin: state.listingFromAdmin,
                totalAuctionsWon: state.totalAuctionsWon,
                search_all_upcomingproducts: state.search_all_upcomingproducts,
                totalAuctionsPlaced: state.totalAuctionsPlaced,
                totalPrivateSaleProducts: state.totalPrivateSaleProducts,
                all_offers: state.all_offers,
                savedSearchModel: state.savedSearchModel,
                phonemodelPopup: state.phonemodelPopup,
                getAllTtwBidHistory,
                clearResponse,
                getAllUpcomingProducts,
                getAllDashboardProducts,
                getTtwAllDashboardProducts,
                getAllSearchProducts,
                listingPartipationRequest,
                getAllSearchProductsManifest,
                getAllInvoiceProducts,
                getAllReturnInvoiceProducts,
                getAllUserInvoices,
                getAllUserReturns,
                getAllSimilarProducts,
                addWatchlist,
                removeWatchlist,
                getAllActiveSorts,
                getAllBidHistory,
                addSavedSearch,
                getSavedSearch,
                removeSavedSearch,
                getFollowers,
                addFollower,
                removeFollower,
                updateFollower,
                getTrackChart,
                getAllTtwBidHistoryByUser,
                getAllProductsFromAdmin,
                getTotalAuctionsWon,
                getTotalAuctionsPlaced,
                getTotalPrivateSaleProducts,
                updateChartmetricArtistImage,
                getAllAdminOffers,
                changeOfferStatus,
                setSavedSearchModel,
                setPhonemodelPopup,
                increaseProductViewCount,
                uploadXLSXFile,
            }}
        >
            {props.children}
        </ProductContext.Provider>
    )
}

export default ProductState
