import React, { useReducer } from 'react'
import AuctionContext from './auctionContext'
import AuctionReducer from './auctionReducer'
import { apiCallSeller } from '../../../common/api'
import { response } from '../../common'

import {
    GET_ALL_AUCTIONS,
    GET_SINGLE_AUCTION,
    GET_AUCTION_PROJECTS,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_ALL_LOT,
} from './auctionTypes'

const AuctionState = (props) => {
    const initialState = {
        single_auction: {
            record: {},
        },
        auction_projects: {
            records: [],
        },
        all_auctions: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        all_lot_count: {
            lot_count: 0,
            auction_count: 0,
            buynow_count: 0,
        },
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(AuctionReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getAllAuctions = async (formData, fromVariable = 'Auctions') => {
        try {
            const [res] = await Promise.all([
                apiCallSeller('post', 'lots', formData, '', 'auction'),
            ])

            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_AUCTIONS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        from: fromVariable,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, fromVariable)
            } else {
                resp.commonErrorResponse(fromVariable)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllLotCount = async (formData, fromVariable = 'lotcount') => {
        try {
            const [res] = await Promise.all([
                apiCallSeller('post', 'getMainDashboardDataMore', formData, '', 'dashboard'),
            ])

            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_LOT,
                    payload: {
                        lot_count: res.data.data.mainDashboardData.lotCount,
                        auction_count: res.data.data.mainDashboardData.auctionCount,
                        buynow_count: res.data.data.mainDashboardData.buynowCount,
                        from: fromVariable,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, fromVariable)
            } else {
                resp.commonErrorResponse(fromVariable)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const auctionAction = async (formData) => {
        try {
            const from = 'auctionAction'
            const [res] = await Promise.all([
                apiCallSeller('post', 'auctionAction', formData, '', 'auction'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getSingleAuction = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCallSeller('post', 'getSingleAuction', formData, '', 'auction'),
            ])
            const from = 'AuctionManagement'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_AUCTION,
                    payload: {
                        record: res.data.data.responseData.record
                            ? res.data.data.responseData.record
                            : {},
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const changeAuctionStatus = async (formData) => {
        try {
            const from = 'auctionchange'
            const [res] = await Promise.all([
                apiCallSeller('post', 'changeStatus', formData, '', 'auction'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllAuctProjects = async (formData, from) => {
        try {
            const [res] = await Promise.all([
                apiCallSeller('post', 'getAllProjectsAssociated', formData, '', 'auction'),
            ])
            const fromVariable = from ? from : 'AuctionManagement'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_AUCTION_PROJECTS,
                    payload: {
                        records: res.data.data.responseData.records
                            ? res.data.data.responseData.records
                            : [],
                        from: fromVariable,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const assignAuctionLot = async (formData) => {
        try {
            const from = 'auctionLot'
            const [res] = await Promise.all([
                apiCallSeller('post', 'addtoAuction', formData, '', 'auction'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <AuctionContext.Provider
            value={{
                all_auctions: state.all_auctions,
                responseStatus: state.responseStatus,
                single_auction: state.single_auction,
                auction_projects: state.auction_projects,
                all_lot_count: state.all_lot_count,
                clearResponse,
                getAllAuctions,
                auctionAction,
                getSingleAuction,
                getAllAuctProjects,
                assignAuctionLot,
                changeAuctionStatus,
                getAllLotCount,
            }}
        >
            {props.children}
        </AuctionContext.Provider>
    )
}

export default AuctionState
