import { COMMON_VALUES, RESPONSE_STATUS, CLEAR_RESPONSE, SWITCH_LANGUAGE } from './commonTypes'

export default (state, action) => {
    switch (action.type) {
        case COMMON_VALUES:
            return {
                ...state,
                allCategory: action.payload.allCategory,
                allCompany: action.payload.allCompany,
                allLocations: action.payload.allLocations,
                allSubCategories: action.payload.allSubCategories,
                allCondition: action.payload.allCondition,
                allhomeBanner: action.payload.allhomeBanner,
                allBusinessCategory: action.payload.allBusinessCategory,
                allVatCategory: action.payload.allVatCategory,
                allBidIncrements: action.payload.allBidIncrements,
                allConfigurationVariables: action.payload.allConfigurationVariables,
                allNotifications: action.payload.allNotifications,
                bidIncrementDefault: action.payload.bidIncrementDefault,
                configFeatures: action.payload.configFeatures,
                configVariables: action.payload.configVariables,
                alldata_all_dropdown: action.payload.alldata_all_dropdown,
                phrases: action.payload.phrases,
                languages: action.payload.languages,
            }
        case SWITCH_LANGUAGE:
            return {
                ...state,
                currentLanguage: action.payload,
            }
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
