import React, { useReducer } from 'react'
import TapMatchersContext from './tapMatchersContext'
import TapMatchersReducer from './tapMatchersReducer'
import { apiCall } from '../../../product/common/api'
import { response } from '../../../product/context/common'

import { RESPONSE_STATUS, CLEAR_RESPONSE } from './tapMatchersTypes'

const TapMatchersState = (props) => {
    const initialState = {
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(TapMatchersReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const tapMachersSearch = async (payload) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'search', payload, '', 'tapmachers')])
            resp.commonResponse(res.data, 'tapMatchers')
        } catch (err) {
            resp.commonErrorResponse('tapMatchers')
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <TapMatchersContext.Provider
            value={{
                responseStatus: state.responseStatus,
                tapMachersSearch,
                clearResponse,
            }}
        >
            {props.children}
        </TapMatchersContext.Provider>
    )
}

export default TapMatchersState
