import React, { useContext } from 'react'
import MuiPhoneInput from 'material-ui-phone-number'
import CustomCommonContext from '../../../../custom/context/common/commonContext'

const CustomPhone = (props) => {
    const customCommonContext = useContext(CustomCommonContext)
    const { allConfigurationVariables } = customCommonContext

    const shrink = props.shrink ? props.shrink.toString() : 'false'

    const onFocus = (event) => {
        console.log(event, 'evnn')
        if (props.disableAutocomplete) {
            event.target.removeAttribute('readonly')
        }
        if (props.onFocus) {
            props.onFocus(event)
        }
    }

    const handlePhoneNumberChange = (value) => {
        if (props.onError) {
            const divElement = document.querySelector(
                `.MuiInputBase-root.Mui-error input[name="${props.name}"]`,
            )

            // Check if the "Mui-error" class is present
            let isError = false
            if (divElement) {
                console.log(divElement.parentNode, 'divElement.parentNode')
                if (divElement.parentNode.classList.contains('Mui-error')) {
                    if (props.minChar && props.maxChar) {
                        if (value.length > props.minChar && value.length < props.maxChar) {
                            isError = true
                        } else {
                            isError = false
                        }
                    }
                } else {
                    isError = false
                }
            }

            if (isError) {
                // Handle the error, e.g., log it

                props.onError(true)

                console.log('Error handling phone number')
            } else {
                // Your logic when there is no error

                props.onError(false)

                props.onChange(value)
            }
        } else {
            props.onChange(value)
        }
    }

    const inputProps = props.disableAutocomplete
        ? { ...props.inputProps, autoComplete: 'new-password', readOnly: true }
        : props.inputProps

    return (
        <div className="customInput">
            {props.upperLabel ? (
                <>
                    <label>{props.label}</label>
                    <MuiPhoneInput
                        value={
                            props.value
                                ? props.value
                                : allConfigurationVariables &&
                                  allConfigurationVariables.country_code
                                ? allConfigurationVariables.country_code
                                : '+1'
                        }
                        autoFocus={props.autoFocus}
                        name={props.name}
                        // onChange={props.onChange}
                        onChange={handlePhoneNumberChange}
                        onFocus={onFocus}
                        InputProps={{
                            inputProps: inputProps,
                            startAdornment: props.startAdornment,
                            shrink: shrink,
                        }}
                        id={props.id}
                        type={props.type}
                        size={props.size}
                        disabled={props.disabled}
                        variant={props.variant ? props.variant : 'outlined'}
                        placeholder={props.placeholder}
                        error={props.error}
                        helperText={props.helperText}
                        countryCodeEditable={props.countryCodeEditable}
                    />
                </>
            ) : (
                <MuiPhoneInput
                    value={
                        props.value
                            ? props.value
                            : allConfigurationVariables && allConfigurationVariables.country_code
                            ? allConfigurationVariables.country_code
                            : '+1'
                    }
                    autoFocus={props.autoFocus}
                    name={props.name}
                    onChange={handlePhoneNumberChange}
                    onFocus={onFocus}
                    InputProps={{
                        inputProps: inputProps,
                        startAdornment: props.startAdornment,
                        shrink: shrink,
                    }}
                    id={props.id}
                    label={props.label}
                    type={props.type}
                    size={props.size}
                    disabled={props.disabled}
                    variant={props.variant ? props.variant : 'outlined'}
                    placeholder={props.placeholder}
                    error={props.error}
                    helperText={props.helperText}
                    countryCodeEditable={props.countryCodeEditable}
                />
            )}
        </div>
    )
}

export default CustomPhone
