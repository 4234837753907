import React, { useState, useContext, useEffect } from 'react'
import { Button } from '@material-ui/core'
import './Footer.css'
import AutopltContext from '../../../../product/context/autopilot/autopltContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { DPW_LOGO, DPW_LOGO_WHITE, LOGO } from '../../../../Utils'
import { mapData } from '../../../../product/common/components'
import { Link } from 'react-router-dom'
const Footer = () => {
    const autopltContext = useContext(AutopltContext)

    const { addCustomer, responseStatus: responseStatusAutoPlt } = autopltContext

    const validationArray = Yup.object({
        email: Yup.string().email('invalid email format').required('required'),
    })

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            addCustomer(values)
        },
    })

    const subscribe = [
        {
            label: 'subscribe to our mailing list',
            placeholder: 'Enter your email address',
            class: 'subsInp',
            type: 'text',
            variant: 'filled',
            name: 'email',
            formik: formik,
        },
    ]

    useEffect(() => {
        if (responseStatusAutoPlt) {
            if (responseStatusAutoPlt.from === 'addCustomer') {
                if (responseStatusAutoPlt.status === 'success') {
                    formik.values.email = ''
                }
            }
        }
    }, [responseStatusAutoPlt])

    return (
        <footer className="noPrint">
            <div className="footCnt ">
                <div className="container footContainer h-100">
                    <div className="d-flex justify-content-between flex-wrap footLinks pt-4">
                        <div className="footLogo d-flex">
                            <img src={DPW_LOGO} alt="Asset Portal" />
                        </div>
                        <ul>
                            <li>
                                <Link id="footer_faq" to="/faq">
                                    FAQ
                                </Link>
                            </li>
                            <li>
                                <Link id="footer_how_it_work" to="/how_it_works">
                                    How It Works
                                </Link>
                            </li>
                            <li>
                                <Link id="footer_blog" to="/blog">
                                    Blog
                                </Link>
                            </li>
                            <li>
                                <Link id="footer_contactus" to="/contact">
                                    Contact Us
                                </Link>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <Link id="footer_privacy" to="/privacy_policy">
                                    Privacy Policy
                                </Link>
                            </li>
                            <li>
                                <Link to="/refund_policy">Refund Policies</Link>
                            </li>
                            <li>
                                <Link id="footer_terms" to="/terms_of_service">
                                    Terms and Conditions
                                </Link>
                            </li>
                            <li>
                                <Link to="/shipping_policy">Shipping Policy</Link>
                            </li>
                        </ul>
                        <ul className="footSocial d-flex justify-content-start">
                            <li>
                                <a
                                    href="https://www.facebook.com/primismedical/"
                                    target="_blank"
                                    without
                                    rel="noreferrer"
                                    id="footer_facebook"
                                >
                                    <img src="/assets/svg/facebook.svg" alt="Facebook logo" />
                                </a>
                            </li>

                            <li>
                                <a
                                    href="https://www.instagram.com/primismedical/"
                                    target="_blank"
                                    without
                                    rel="noreferrer"
                                    id="footer_instagram"
                                >
                                    <img src="/assets/svg/instagram.svg" alt="Instagram logo" />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.linkedin.com/in/douglas-beck-primismedical/"
                                    target="_blank"
                                    without
                                    rel="noreferrer"
                                    id="footer_linkedin"
                                >
                                    <img src="/assets/svg/linkedin.svg" alt="LinkedIn logo" />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://twitter.com/MedicalPrimis/"
                                    target="_blank"
                                    without
                                    rel="noreferrer"
                                    id="footer_twitter"
                                >
                                    <img src="/assets/svg/twitter.svg" alt="Twitter logo" />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <ul className="d-flex justify-content-between footer-brands">
                        <li>
                            <img src="/assets/svg/DotMedCertified.svg" alt="DotMedCertified" />
                        </li>
                        <li>
                            <img src="/assets/svg/Print-Seals.svg" alt="Print-Seals" />
                        </li>
                        <li>
                            <img
                                src="/assets/svg/thomas-covid19-response-supplier.svg"
                                alt="Thomas Covid19"
                            />
                        </li>
                        <li>
                            <img src="/assets/svg/verified-vendor.svg" alt="Verified vendor" />
                        </li>
                    </ul>
                    <h6 className="poweredBy">
                        SERVING ALL 50 STATES INCLUDING UTAH, IDAHO, NEVADA, AND COLORADO
                    </h6>
                </div>
                <p className="footCopyright text-center px-4" dir="ltr">
                    Copyright © 2023 The Asset Portal. All Rights Reserved.
                </p>
            </div>
        </footer>
    )
}

export default Footer
